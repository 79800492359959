import * as React from 'react'
import { Div, Flex } from 'maker-ui'
import { Helmet } from 'react-helmet'

import { FormSection } from '../components/Form'

const bgImage = require('../assets/background.jpg')

const IndexPage = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Support Our Cause | LUPE Action</title>
      </Helmet>
      <Div
        css={{
          position: 'relative',
          background: `url(${bgImage})`,
          backgroundSize: 'cover',
          minHeight: '70vh',
          paddingTop: [200, '30vh'],
          ':before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.4)',
          },
        }}>
        <Flex css={{ margin: '0 auto', padding: '20px' }}>
          <Flex
            direction="column"
            css={{
              borderTop: `8px solid var(--color-secondary)`,
              borderBottom: `8px solid var(--color-accent)`,
              padding: ['40px 30px 30px', '50px 50px 30px'],
              background: '#fff',
              width: '100%',
              maxWidth: 700,
              margin: '0 auto',
              zIndex: 1,
              transform: 'translateY(120px)',
              boxShadow: '6px 10px 15px rgba(0, 0, 0, 0.12)',
              h2: {
                fontSize: 25,
                lineHeight: 1.33,
                '@media (min-width: 768px)': {
                  fontSize: 32,
                },
              },
              strong: {
                color: 'var(--color-secondary)',
              },
              p: {
                fontSize: 16,
                lineHeight: 1.5,
                '@media (min-width: 768px)': {
                  fontSize: 18,
                },
              },
            }}>
            <h2>
              Join <strong>#LUPEAction</strong> by contacting your Local
              Representatives!
            </h2>
            <p>
              LUPE Action advocates for the rights of Latinas to have a seat at
              the table in government and politics so that our community can
              have a voice in enacting policies that encourage economic
              opportunity, equity and inclusion across all areas of society.
            </p>
            <p>
              Fill out the form to send an email or a tweet to your local
              representatives and let your voice be heard!
            </p>
            <a
              className="btn-floating btn btn-tw"
              type="button"
              role="button"
              title="Share on twitter"
              href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fparse.com"
              rel="noopener">
              <i className="fab fa-2x fa-twitter"></i>
            </a>
            <FormSection />
          </Flex>
        </Flex>
      </Div>
      <Div
        css={{
          background: 'var(--color-primary)',
          height: 300,
        }}
      />
    </>
  )
}

export default IndexPage
