import * as React from 'react'
import { Grid, Flex, Div, Button } from 'maker-ui'
import { Formik, Field, Form, FormikHelpers } from 'formik'

import { TwitterIcon, MailIcon } from '../components/Icons'

interface Values {
  firstName: string
  lastName: string
  email: string
  zip: string
}

export const FormSection = () => {
  return (
    <Div
      css={{
        margin: '20px 0 50px',
        '.form-field': {
          display: 'flex',
          flexDirection: 'column',
          input: {
            height: 55,
            padding: '0 20px',
            fontSize: 16,
          },
          label: {
            fontWeight: 'bold',
            marginBottom: 12,
            fontSize: 17,
            color: 'var(--color-primary)',
          },
        },
      }}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          zip: '',
        }}
        onSubmit={(
          values: Values,
          { setSubmitting, setStatus }: FormikHelpers<Values>
        ) => {
          console.log('values are', values)
          fetch('/.netlify/functions/email', {
            method: 'POST',
            body: JSON.stringify(values),
          })
            .then(async (response) => {
              const data = await response.json()
              setStatus('success')
              console.log('Response from server is', data)
            })
            .catch((error) => {
              console.error('Error from server is', error)
            })
          // setTimeout(() => {
          //   alert(
          //     JSON.stringify(
          //       'For production mode, connect this form to a valid email service',
          //       null,
          //       2
          //     )
          //   )
          //   setSubmitting(false)
          // }, 500)
        }}>
        {({ errors, touched, status }) => (
          <Form>
            {status === 'success' ? (
              <Flex
                align="center"
                justify="center"
                css={{
                  height: 288,
                  fontSize: 25,
                  border: '2px solid #eceff2',
                  background: '#fbfbfb',
                }}>
                Thanks for showing your support!
              </Flex>
            ) : (
              <>
                <Div
                  css={{
                    display: 'grid',
                    gridTemplateRows: ['repeat(4, 1fr)', '1fr 1fr'],
                    gridTemplateAreas: [
                      '"first""last""email""zip"',
                      '"first first last last" "email email email zip"',
                    ],
                    gridGap: 20,
                    gridTemplateColumns: ['1fr', 'repeat(4, 1fr)'],
                    marginBottom: 40,
                  }}>
                  <Div className="form-field" css={{ gridArea: 'first' }}>
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      id="firstName"
                      name="firstName"
                      placeholder="John"
                      required
                    />
                  </Div>
                  <Div className="form-field" css={{ gridArea: 'last' }}>
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      id="lastName"
                      name="lastName"
                      placeholder="Doe"
                      required
                    />
                  </Div>
                  <Div className="form-field" css={{ gridArea: 'email' }}>
                    <label htmlFor="email">Email</label>
                    {errors.email && touched.email && <div>{errors.email}</div>}
                    <Field
                      id="email"
                      name="email"
                      placeholder="john@lupeaction.org"
                      type="email"
                      required
                    />
                  </Div>
                  <Div className="form-field" css={{ gridArea: 'zip' }}>
                    <label htmlFor="zip">Zip Code</label>
                    <Field
                      id="zip"
                      name="zip"
                      placeholder="07920"
                      type="text"
                      pattern="[0-9]*"
                      required
                    />
                  </Div>
                </Div>
                <Flex
                  align="center"
                  justify="flex-end"
                  css={{
                    flexDirection: ['column', 'row'],
                    'button, a': {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 58,
                      padding: '0 30px',
                      color: '#fff',
                      fontSize: 15,
                      textTransform: 'uppercase',
                      letterSpacing: 1.5,
                      border: 'none',
                      borderRadius: 3,
                      boxShadow: '0px 3.2093px 3.2093px rgba(0, 0, 0, 0.25)',
                      '@media (max-width: 768px)': {
                        margin: '0 0 15px !important',
                        width: '100%',
                      },
                      svg: {
                        marginRight: 15,
                        fill: '#fff',
                      },
                    },
                    '.tweet': {
                      marginRight: 20,
                      background: '#3C76C7',
                    },
                    '.submit': {
                      background: 'var(--color-primary)',
                    },
                  }}>
                  <a
                    className="tweet twitter-share-button"
                    target="_blank"
                    href={`https://twitter.com/intent/tweet?text=Tweet from the LUPE Action website&via=lupeactionnj`}>
                    <TwitterIcon css={{ height: 16 }} />
                    Tweet
                  </a>
                  <button className="submit" type="submit">
                    <MailIcon css={{ height: 18 }} />
                    Send Email
                  </button>
                </Flex>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Div>
  )
}
